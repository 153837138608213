<template>
  <v-container
    fluid
    fill-height
    :class="$vuetify && $vuetify.breakpoint.mobile ? 'pa-0' : ''"
  >
    <v-card
      class="documents-table-wrapper rounded-t-xs rounded-b-xs mt-n1"
    >
      <div class="ma-0">
        <v-tabs
          v-model="selectedTab"
          active-class="white"
          slider-color="accent"
          grow
          next-icon="mdi-arrow-right"
          prev-icon="mdi-arrow-left"
          show-arrows
          class="mt-1"
        >
          <v-tab
            v-for="(t, index) in actualTabs"
            :key="index"
            :disabled="t.disabled"
            @change="setDocumentsTab(index, t)"
          >
            <div>
              <div>{{ t.text }}</div>
              <div
                style="font-size:10px;"
              >
                {{ t.title }}
              </div>
            </div>
          </v-tab>
          <v-tab-item
            v-for="(t, index) in actualTabs"
            :key="index"
          >
            <DocumentsTable
              :company="t.company"
              :group="t.group"
              :is-company-tab="t.isCompanyTab"
              :is-group-tab="t.isGroupTab"
              :is-my-tab="t.isMyTab"
              :user-role="t.userRole"
              :is-single-acc="t.isSingleAcc"
              :is-shared-with-me="t.isSharedWithMe"
              :is-lavvira-tab="t.isLavviraTab"
              :actual-tab="t"
              :tab-changed="tabChanged"
              @right-click="rightClick"
              @right-click-mobile="onRightClickMobile"
              @route-elements="onRouteElements"
            />
          </v-tab-item>
        </v-tabs>
        <v-menu
          v-model="showRightClickMenu"
          offset-y
          :position-x="menuPositionX"
          :position-y="menuPositionY"
          @input="closeMenu()"
        >
          <v-list>
            <v-list-item-group v-model="selectedItem">
              <v-list-item
                v-for="(itm, i) in rightClickMenuItems"
                :key="i"
                :disabled="checkDisabledStatus(itm)"
                @click="fireActionEvent(itm, item, actualTab)"
              >
                <v-list-item-icon>
                  <v-icon>
                    {{ itm.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ itm.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </div>
      <DocumentMoveDialog
        ref="moveDialog"
        :route-elements="routeElements"
        :actual-tab="actualTab"
      />
      <ShareFolders
        ref="shareFolders"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import {
  mdiChevronDown,
  mdiCog,
  mdiFolderEditOutline,
  mdiFolderEyeOutline,
  mdiFolderArrowRightOutline,
  mdiShareVariantOutline,
  mdiFolderArrowDownOutline
} from '@mdi/js'
import generalMixin from '@/utils/generalMixin.js'
import DocumentMixin from './DocumentMixins/DocumentMixin'
import DocumentsTable from './DocumentsTable.vue'
import DocumentMoveDialog from '../../components/dialogs/DocumentMoveDialog.vue'
import ShareFolders from '../../components/dialogs/ShareFolders.vue'

export default {
  components: {
    DocumentsTable,
    DocumentMoveDialog,
    ShareFolders
  },
  mixins: [generalMixin, DocumentMixin],
  data () {
    return {
      tabChanged: false,
      actualTab: null,
      item: null,
      showRightClickMenu: false,
      menuPositionX: null,
      menuPositionY: null,
      selectedTab: Number(localStorage.getItem('activeDocumentsTabIndex')) || 0,
      selectedItem: null,
      routeElements: null,
      icons: {
        arrowDown: mdiChevronDown,
        cog: mdiCog,
        editFolder: mdiFolderEditOutline,
        openFolder: mdiFolderEyeOutline,
        moveFolder: mdiFolderArrowRightOutline,
        share: mdiShareVariantOutline,
        exportFolder: mdiFolderArrowDownOutline
      }
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      folders: state => state.documents.folders,
      sharedWithMeFolders: state => state.documents.sharedWithMeFolders,
      companyFolders: state => state.companyDocuments.companyFolders,
      groupFolders: state => state.groupDocuments.groupFolders
    }),
    actualTabs () {
      let tabs = []
      if (this.account && !this.account.companyId) {
        tabs.push({
          text: this.$t('common|my'),
          title: this.$t('documents|documents'),
          isCompanyTab: false,
          isGroupTab: false,
          isSingleAcc: true,
          isMyTab: true,
          isLavviraTab: false
        })
      }
      if (!this.company && this.account && !this.account.companyId && this.account.accountType === 'lawyer') {
        tabs.push({
          text: this.$t('common|shared_with_me'),
          title: this.$t('documents|documents'),
          isCompanyTab: false,
          isGroupTab: false,
          isSingleAcc: true,
          isSharedWithMe: true,
          isMyTab: true,
          isLavviraTab: false
        })
      }
      if (this.company && this.account && this.account.companyId) {
        const userRole = this.verifyUserRole(this.company, this.account)
        // verifyUserRole => generalMixin
        if (userRole !== 'regular') {
          tabs.push({
            text: this.$t('common|my'),
            title: this.$t('documents|documents'),
            userRole: userRole,
            isCompanyTab: false,
            isGroupTab: false,
            isSingleAcc: false,
            isLavviraTab: false,
            isMyTab: true
          })
          tabs.push({
            text: this.$t('common|shared_with_me'),
            title: this.$t('documents|documents'),
            isCompanyTab: false,
            isGroupTab: false,
            isSingleAcc: false,
            isSharedWithMe: true,
            isMyTab: false,
            isLavviraTab: false
          })
          tabs.push({
            text: this.company.companyData.companyName,
            title: this.$t('documents|documents'),
            company: this.company,
            isCompanyTab: true,
            isGroupTab: false,
            userRole: userRole,
            isSingleAcc: false,
            isLavviraTab: false,
            isMyTab: false,
            disabled: this.company.subscriptionStatus === 'unpaid' || false
          })
          if (this.company.groups && this.company.groups.length) {
            for (let i = 0; i < this.company.groups.length; i++) {
              const group = this.company.groups[i]
              const accountParticipation = group.groupMembers.findIndex(gm => gm._id === this.account._id)
              if (accountParticipation !== -1) {
                tabs.push({
                  text: group.groupName,
                  title: this.$t('documents|documents'),
                  group: group,
                  isCompanyTab: false,
                  isGroupTab: true,
                  userRole: userRole,
                  isSingleAcc: false,
                  isLavviraTab: false,
                  isMyTab: false,
                  disabled: this.company.subscriptionStatus === 'unpaid' || false
                })
              }
            }
          }
        } else if (userRole === 'regular') {
          tabs.push({
            text: this.company.companyData.companyName,
            title: this.$t('documents|documents'),
            company: this.company,
            isCompanyTab: true,
            isGroupTab: false,
            isMyTab: false,
            userRole: userRole,
            disabled: this.company.subscriptionStatus === 'unpaid' || false
          })
          if (this.company.groups && this.company.groups.length) {
            for (let i = 0; i < this.company.groups.length; i++) {
              const group = this.company.groups[i]
              const accountParticipation = group.groupMembers.findIndex(gm => gm._id === this.account._id)
              if (accountParticipation !== -1) {
                tabs.push({
                  text: group.groupName,
                  title: this.$t('documents|documents'),
                  group: group,
                  isCompanyTab: false,
                  isGroupTab: true,
                  isMyTab: false,
                  userRole: userRole,
                  isLavviraTab: false,
                  disabled: this.company.subscriptionStatus === 'unpaid' || false
                })
              }
            }
          }
        }
      }
      return tabs
    },
    rightClickMenuItems () {
      if (this.item && this.item.collectionType === 'Folders') {
        return [
          {
            text: this.$t('actions|open'),
            icon: this.icons.openFolder,
            action: 'open-folder'
          },
          {
            text: this.$t('folders|change_folder_name'),
            icon: this.icons.editFolder,
            action: 'change-folder-name'
          },
          {
            text: this.$t('folders|share_folder'),
            icon: this.icons.share,
            action: 'share-folder'
          },
          {
            text: this.$t('folders|export_folder'),
            icon: this.icons.exportFolder,
            action: 'export-folder'
          },
          {
            text: this.$t('folders|delete_folder'),
            icon: 'mdi-trash-can',
            action: 'delete-folder'
          }
        ]
      }
      if (this.item) {
        return [
          {
            text: this.$t('actions|open'),
            icon: 'mdi-file-eye',
            action: 'open-doc'
          },
          {
            text: this.$t('actions|move_to'),
            icon: this.icons.moveFolder,
            action: 'move-doc-to'
          },
          {
            text: this.$t('actions|change_document_name'),
            icon: 'mdi-pencil',
            action: 'change-name'
          },
          {
            text: this.$t('actions|export'),
            icon: 'mdi-export',
            action: 'export-doc'
          },
          {
            text: this.$t('actions|share'),
            icon: 'mdi-share-variant',
            action: 'share-doc'
          },
          {
            text: this.$t('actions|assign'),
            icon: 'mdi-paperclip',
            action: 'assign-doc'
          },
          {
            text: this.$t('actions|delete'),
            icon: 'mdi-delete',
            action: 'delete-doc'
          }
        ]
      } else {
        return [
          {
            text: this.$t('folders|create_folder'),
            icon: 'mdi-folder-plus-outline',
            action: 'create-folder'
          }
        ]
      }
    },
    activeFolder () {
      let _activeFolder = null
      const splittedRoute = this.$route.path.split('/')
      if (splittedRoute[3]) _activeFolder = splittedRoute[3]
      return _activeFolder
    },
    activeFolderObject () {
      if (!this.activeFolder) {
        return null
      } else {
        const activeFolder = this.folders.find(f => f._id === this.activeFolder) ||
        this.sharedWithMeFolders.find(f => f._id === this.activeFolder) ||
        this.companyFolders.find(f => f._id === this.activeFolder) ||
        this.groupFolders.find(f => f._id === this.activeFolder)
        return activeFolder
      }
    }
  },
  watch: {
    showRightClickMenu (val) {
      if (!val) {
        this.selectedItem = null
      }
    }
  },
  mounted () {
    const index = localStorage.getItem('activeDocumentsTabIndex')
    if (index) this.actualTab = this.actualTabs[index]
    else this.actualTab = this.actualTabs[0]
  },
  methods: {
    setDocumentsTab (idx, tab) {
      this.actualTab = tab
      localStorage.setItem('activeDocumentsTabIndex', idx)
      this.tabChanged = true
      setTimeout(() => {
        this.tabChanged = false
      }, 500)
    },
    onRouteElements (elements) {
      this.routeElements = elements
    },
    onRightClickMobile (info) {
      if (info && info.item && info.showRightClickMenu) {
        this.item = info.item
        this.showRightClickMenu = info.showRightClickMenu
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .documents-table-wrapper {
    width: 100%;
    height: 100%;
  }
</style>
